@import './_variables.css';

:root{
    /* Body  */
    --body-bg: rgb(var(--white));

    /* Action bar */
    --actionbar-bg: rgb(var(--purple));
    --actionbar-color: rgba(var(--white), var(--opacity-75));
    --actionbar-hover-color: rgb(var(--white));

    /* Footer */
    --footer-bg: rgb(var(--white));

    /* Hero */
    --hero-bg: rgb(var(--white));

    /* Navbar */
    --navbar-bg: rgb(var(--white));

    /* Property Page */
    --propertypage-image-bg: rgb(var(--light-gray));

    /* Divider */
    --divider-color: rgb(var(--light-gray));

    /* Carousel */
    --carousel-image-bg: rgb(var(--light-gray));
    --carousel-button-bg: rgb(var(--white), var(--opacity-50));
    --carousel-indicator-bg: rgb(var(--white), var(--opacity-50));
    --carousel-button-active-bg: rgb(var(--white), var(--opacity-75));

    /* Buttons */
    --button-primary-bg: rgb(var(--purple));
    --button-primary-color: rgb(var(--white));
    --button-primary-hover-bg: rgb(var(--purple-dark));
    --button-primary-hover-color: rgb(var(--white));
    --button-primary-stroke: rgb(var(--purple));
    --button-primary-solid: rgb(var(--white));
    --button-primary-hover-stroke: rgb(var(--purple-dark));
    --button-primary-hover-solid: rgb(var(--purple-light));

    /* Texts */
    --active-text-color: rgb(var(--purple));
    --active-text-hover-color: rgb(var(--purple-dark));
    --primary-text-color: rgb(var(--black-charcoal));
    --primary-text-color-inverted: rgb(var(--white));
    --secondary-text-color: rgb(var(--gray));
}
