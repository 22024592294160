@import './stylesheet/_colors.css';
@import './stylesheet/_variables.css';

.privacypolicy-root {
  background-color: var(--navbar-bg);
  color: var(--primary-text-color);
  margin: 24px 0px;
  width: 100%;
}

.privacypolicy-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: var(--site-width);
  padding: 8px 0px;
}

@media screen and (max-width: 1280px) {
  .privacypolicy-container  {
      padding: 8px var(--outer-padding-smaller-screen-x);
  }
}