* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: var(--ff-base);
    background-color: var(--body-bg);
    padding: 0;
}

.hidden {
  display: none;
}

@media screen and (max-width: 786px) {
  .hide-tablet-and-below  {
      display: none;
  }
}

@media screen and (max-width: 480px) {
  .hide-mobile-and-below  {
      display: none;
  }
}

a, Link {
  color: var(--primary-text-color);
  text-decoration: none;
}

.active-text-color {
  color: var(--active-text-color);
}

.active-text-color:hover {
  color: var(--active-text-hover-color);
}
