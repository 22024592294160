:root{
    /* Site width */
    --site-width: 1280px;
    --outer-padding-smaller-screen-x: 12px; 

    /* Spacing units. */
    --su-05: 0.125rem; /* 2px */
    --su-1: 0.25rem; /* 4px */
    --su-2: 0.5rem; /* 8px */
    --su-3: 0.75rem; /* 12px */
    --su-4: 1rem; /* 16px */
    --su-5: 1.25rem; /* 20px */
    --su-6: 1.5rem; /* 24px */
    --su-7: 2rem; /* 32px */
    --su-8: 3rem; /* 48px */
    --su-9: 4rem; /* 64px */
    --su-10: 8rem; /* 128px */

    /* Border radius */
    --radius-s: 0.25rem; /* 4px */
    --radius-base: 0.50rem; /* 8px */
    --radius-l: 0.75rem; /* 12px */

    /* Icons */
    --icon-size-base: 16px;

    /* Logo */
    --logo-base: 64px;

    /* Font sizes */
    --fs-xs: 0.75rem; /* 12px */
    --fs-s: 0.875rem; /* 14px */
    --fs-base: 1rem; /* 16px */
    --fs-l: 1.125rem; /* 18px */
    --fs-xl: 1.25rem; /* 20px */
    --fs-2xl: 1.5rem; /* 24px */
    --fs-3xl: 1.875rem; /* 30px */
    --fs-4xl: 2.25rem; /* 36px */
    --fs-5xl: 3rem; /* 48px */

    /* Font weights */
    --fw-normal: 400;
    --fw-medium: 500;
    --fw-bold: 700;
    --fw-heavy: 800;

    /* Font families */
    --ff-base: 'Questrial', sans-serif;

    /* Opacity */
    --opacity-0: 0.00001;
    --opacity-25: 0.25;
    --opacity-50: 0.5;
    --opacity-75: 0.75;
    --opacity-100: 1; 

    /* Breakpoints */
    --breakpoint-xs: 480px;
    --breakpoint-s: 640px;
    --breakpoint-m: 768px;
    --breakpoint-l: 1024px;
    --breakpoint-xl: 1280px;
    
    /* Pure Colors */
    --white: 255, 255, 255;
    --black: 0, 0, 0;

    /* Brand Colors */
    --purple: 60, 79, 118;
    --purple-dark: 42, 56, 84;
    --purple-light: 103, 127, 177;

    
    --white-silver: 196, 196, 196;
    --black-charcoal: 39, 39, 39;
    --gray: 114, 114, 114;
    --light-gray: 231, 231, 231;
    --red: 238, 96, 85; 
    --blue: 38, 109, 211;
    --green: 96, 211, 148;
    --yellow: 255, 224, 102;
}
